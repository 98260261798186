/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { DEFAULT_LAYOUT } from '@/data/config/DEFAULTS';
import { ParsedUrlQuery } from 'querystring';
import { Layout } from '@/data/types/Layout';
import { getNormalizedLayout } from '@/data/utils/getNormalizedLayout';
import { getPageDataFromId, usePageDataFromId } from '@/data/_PageDataFromId';
import { GetServerSidePropsContext } from 'next';
import { PageDataFromId } from '@/data/types/PageDataFromId';
import { Cache } from '@/data/types/Cache';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
// import { EventsContext } from '@/data/context/events';
import { ContainerLayout } from '@/data/types/ContainerLayout';
import { isEqual } from 'lodash';
import { getSettings, useSettings } from '@/data/Settings';
import { postPreviewMessage } from '@/data/utils/postPreviewMessage';
import { filterDynamicPaths } from '@/data/utils/filterDynamicPaths';
import { EventsContext } from '@/data/context/events';
import { userSegementsFetcher } from './Content/_UserSegmentsFetcher';
import { isAdvantageSubscribedMember } from '@/data/utils/isAdvantageUser';
import { getCookie } from 'cookies-next';

type ProcessedLayout = { value?: PageDataFromId; processed: Layout; redirect?: string };
type GetLayoutReturn = Promise<ProcessedLayout>;

const dataMap = (contents: any): Layout =>
	(contents ? [contents] : []).reduce(
		(layout, item) => ({
			...layout,
			...getNormalizedLayout(item),
		}),
		DEFAULT_LAYOUT
	);

export const getLayout = async (
	cache: Cache,
	path: ParsedUrlQuery['path'],
	context: GetServerSidePropsContext
): GetLayoutReturn => {
	const processedPath = filterDynamicPaths(path as string[]);
	const page = await getPageDataFromId(cache, processedPath, context);
	const { storeToken: { urlKeywordName } = {} } = await getSettings(cache, context);
	const redirect = page?.redirect ?? page?.page?.redirect;
	const identifier = page?.identifier;
	return redirect && !redirect.endsWith(`/${identifier}`)
		? {
				redirect: (urlKeywordName ? `/${urlKeywordName}${redirect}` : `/${redirect}`)?.replace('//', '/'),
				value: undefined,
				processed: DEFAULT_LAYOUT,
		  }
		: {
				value: page,
				processed: dataMap(page),
		  };
};

export const useLayout = () => {
	const { settings } = useSettings();
	const { data, error, loading, router } = usePageDataFromId();
	const { onPageLoad } = useContext(EventsContext);
	const layoutRef = useRef<ContainerLayout>();
	const { inPreview } = settings;
	const promoCookie = getCookie('PromoCode');
	const promoCookieList = useMemo(
		() => (promoCookie ? (promoCookie.length > 1 ? promoCookie.split('|') : [promoCookie]) : []),
		[promoCookie]
	);

	const { userSegementsData } = userSegementsFetcher();
	const {isAdvMember} = isAdvantageSubscribedMember(userSegementsData);

	const onPageLoadEvent = useCallback(async () => {
		await onPageLoad({
			gtm: {
				pageTitle: data?.page?.title ?? '',
				pagePath: window.location.origin + router?.asPath,
				settings,
				promoCode: promoCookieList?.length ? promoCookieList[0] : '90600',
				advantage_member: isAdvMember,
			},
		});
	}, [onPageLoad, data?.page?.title, router?.asPath, settings, promoCookieList, isAdvMember]);

	useEffect(() => {
		if (inPreview && data?.layout && !isEqual(layoutRef.current, data)) {
			postPreviewMessage({
				message: {
					data: { ...data },
					action: 'PREVIEW_LAYOUT_INITIALIZED',
				},
			});
			layoutRef.current = data;
		}
	}, [data, inPreview]);

	// useEffect(() => data && onPageView(data.page), [onPageLoad, data]);
	useEffect(() => {
		data ? onPageLoadEvent() : null;
	}, [onPageLoadEvent, data]);

	return {
		layout: dataMap(data),
		loading,
		error,
	};
};
